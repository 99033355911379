import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';
import es from 'vuetify/src/locale/es.ts';

Vue.use(Vuetify, {
  iconfont: 'md',
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    primary: '#011936',
    secondary: '#f78da7',
    success: '#011936',
    warning: '#435060',
    error: '#ff5252',
    accent: '#4caf50',
    info2: '#01ACC1',
  },
});
