import 'babel-polyfill';
import Vue from 'vue';
import axios from 'axios';
import './plugins/vuetify';
import './plugins/vue-social-sharing';
import VueAnalytics from 'vue-analytics';
import VueGtag from 'vue-gtag';
import Vuelidate from 'vuelidate';
import VueObserveVisibility from 'vue-observe-visibility';
import { canRefreshToken, shouldRefreshJwt } from '@/utils/jwt';
import App from './App.vue';
import router from './router';
import store from './store';
import './stylus/main.styl';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

axios.interceptors.request.use(config => {
  // Do something before request is sent
  if (canRefreshToken(store.state.jwt) && shouldRefreshJwt(store.state.jwt)) {
    const inst = axios.create();
    inst.post(
      store.state.endpoints.refreshJWT, { token: store.state.jwt },
    ).then(resp => {
      store.commit('updateToken', resp.data.token);
    });
  }
  return config;
}, error => Promise.reject(error));

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueObserveVisibility);

Vue.use(VueGtag, {
  enabled: process.env.NODE_ENV === 'production',
  config: {
    id: 'GTM-WWS8ZN8',
  },
}, router);

Vue.use(VueAnalytics, {
  id: 'UA-85276502-3',
  router,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
